<template>
	<div class="page-guide loaded">
		<div class="content plain">
			<h1>Quick Guide</h1>
			<p>Different software collects file properties in different ways. This guide will explain how to remove file properties from images specifically, in <a href="#" @click.prevent="selected = 0">Windows</a>, on a <a href="#" @click.prevent="selected = 1">Mac</a>, from an <a href="#" @click.prevent="selected = 2">Android</a> phone and from an <a href="#" @click.prevent="selected = 3">iPhone</a>.</p>
			<tab-nav v-model="selected" :items="tabs" class="mb-4" />
			<transition name="fade" mode="out-in">
				<div v-if="!selected" key="d0">
					<h2>
						Windows
					</h2>
					<p>
						<span>Right click</span> on the image file. You will see a menu similar to this one. <span>Select</span><i> Properties</i> (which is usually at the bottom of the options list).
					</p>
					<img src="/img/guide/1.png">
					<p>
						<span>Go to</span> the Details tab within the Properties options. This shows all the data that is sent with the image file. <span>Select</span> the Remove Properties and Personal Information link at the bottom. 
					</p>
					<img src="/img/guide/3.jpg">
					<p>
						Choose Remove the following properties from this file. Choose Select All. Click OK. 
					</p>
					<img src="/img/guide/5.png">
				</div>
				<div v-else-if="selected == 1" key="d1">
					<h2>Mac</h2>
					<p>
</p>
				</div>
				<div v-else-if="selected == 2" key="d2">
					<h2>Any questions?</h2>
					<p>
						Have a question, or want to find out more about the PKF hotline service?<br>
						<span class="text-black font-italic">
							For further information you can contact:
						</span>
					</p>
					<b-container class="p-0">
						<b-row>
							<b-col v-if="org.keyContacts.length" sm="6">
								<h3>{{ orgName }}</h3>
								<p v-for="contact in org.keyContacts" :key="contact.name">
									<b>{{ contact.name }}</b><br />
									<span v-if="contact.position">{{contact.position}}<br></span>
									<span v-if="contact.phone">P: {{contact.phone}}<br></span>
									<a v-if="contact.email" :href="'mailto:'+contact.email">E: {{contact.email}}</a>
								</p>
							</b-col>
							<b-col v-else-if="org.code != 'DFLT'" sm="6">
								<h3>{{ orgName }}</h3>
								<p>
									{{ org.number1800 | format1800 }}<br />
									<a v-if="org.email" :href="'mailto:'+org.email">{{org.email}}</a>
								</p>
							</b-col>
							<b-col sm="6">
								<h3>PKF Integrity Serices (BMNS) Pty Ltd</h3>
								<p>
									<b>Viv Mercer</b><br />
									Manager<br />
									P: <a href="tel:+61292376560">+61 2 9237 6560</a><br />
									M: <a href="tel:+61418478849">+61 418 478 849</a><br />
									E: <a href="mailto:vmercer@pkf.com.au">vmercer@pkf.com.au</a>
								</p>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</transition>
			<b-button size="lg" class="d-inline-flex align-items-center p-3 pr-35 mt-3" @click="$router.back()"><b-icon-chevron-left width="24" height="24" class="mr-3 mt-n1" /> Back</b-button>
		</div>
	</div>
</template>
<script>
import { TabNav } from '@/components'
import { BIconChevronLeft } from "bootstrap-vue";
export default {
	name: 'Guide',
	components: {
		TabNav, BIconChevronLeft
	},
	data() {
		return {
			selected: 0,
		}
	},
	computed: {
		org() {
			return this.$store.state.org
		},
		tabs() {
			return ['Windows', 'Mac', 'Android', 'iPhone']
		},
	},
	created() {
		const ua = navigator.userAgent
		if (ua.indexOf("like Mac") != -1) this.selected = 3
		else if (ua.indexOf("Android") != -1) this.selected = 2
		else if (ua.indexOf("Mac") != -1) this.selected = 1
	},
	metaInfo: {
		title: 'Guide',
	},
}
</script>

<style lang="scss">
.page-guide{
	b{
		color: #000;
	}
	p{
		span{
			color: $primary;
		}
		+ img {
			margin: .5rem 2.5rem 2.5rem
		}
	} 
	// p.icon{
	// 	margin-left: 4em;
	// 	position: relative;
	// 	&:before{
	// 		content: '';
	// 		position: absolute;
	// 		left: -4em;
	// 		top: 7px;
	// 		width: 2em;
	// 		height: 2em;
	// 		background-repeat: no-repeat;
	// 		background-size: contain;
	// 	}
	// 	&.icon-comment:before{
	// 		background-image: url(/img/icons/comment.svg);
	// 	}
	// 	&.icon-export:before{
	// 		background-image: url(/img/icons/export.svg);
	// 	}
	// 	&.icon-resume:before{
	// 		background-image: url(/img/icons/resume.svg);
	// 	}
	// 	&.rotate-icon-180:before{
	// 		transform: rotate(180deg);
	// 	}
	// }
	h3 {
		font-weight: normal;
	}
}
</style>